<template>
    <div class="w-full h-full flex flex-col">
        
        <div class="flex-grow overflow-auto p-6 md:p-12">
            <div 
                v-if="loading == 'fetching'"
                class="h-full flex items-center justify-center">
                
                <div class="spinner"></div>
            </div>
            <div 
                v-else-if="portals"
                class="
                    grid
                    gap-6 
                    md:gap-12
                    grid-cols-1
                    lg:grid-cols-2
                    xl:grid-cols-3
                ">
                
                <a 
                    v-for="portal in portals"
                    :key="'portal_'+portal.portalId"
                    @click="portalEdit = portal"
                    class="flex">
                    
                    <PortalCard
                        :portal="portal"
                    />
                </a>
            </div>
        </div>
        
        
        
        <div class="absolute bottom-0 right-0 m-6">
            <button 
                @click="showPortalNew = true"
                class="button submit round">
                
                <i class="fas fa-plus" />
            </button>
        </div>
        
        
        
        <Modal 
            v-if="showPortalNew"
            @close="showPortalNew = false"
            insideClasses="bg-white">
            
            <header slot="header">
                Ny fellesbruker
            </header>
            
            <form 
                @submit.prevent="onPortalCreate()"
                class="pt-2 px-6 pb-6">
                
                <InputSingleline 
                    v-model="portalNew.name"
                    type="text"
                    label="Navn"
                    :required="true"
                />
                
                <label>Tilgangskode</label>
                <em class="opacity-75 mb-3 -mt-2 block">
                    <div v-if="portalNew.accessCodeCheckedOk">
                        Gyldig og unik!
                    </div>
                    <div v-else>
                        <span v-if="!portalNew.accessCode || portalNew.accessCode.length <= 4">
                            Må være unik og inneholde en kombinasjon av over fem tall eller bokstaver.
                            
                        </span>
                        <span v-else-if="loading != 'checkingAccessCode'">
                            Ikke gyldig eller unik. Prøv en annen.
                        </span>
                        
                        <a 
                            v-if="loading != 'checkingAccessCode'"
                            class="link" @click="generateAccessCode()">
                            
                            Generér forslag automatisk
                        </a>
                    </div>
                </em>
                <div class="relative">
                    <input 
                        type="text" 
                        v-model="portalNew.accessCode"
                        @input="onPortalAccessCodeExistCheck()"
                    />
                    
                    <div class="absolute top-0 right-0 h-full flex items-center">
                        
                        <div v-if="loading == 'checkingAccessCode'">
                            <div class="spinner2"></div>
                        </div>
                        <div 
                            v-else-if="portalNew.accessCodeCheckedOk"
                            class="w-8">
                            
                            <i class="fas fa-check text-green" />
                        </div>
                        <div 
                            v-else
                            class="w-8">
                            
                            <i class="fas fa-exclamation-triangle text-red-500"></i>
                        </div>
                    </div>
                </div>
            </form>
            
            <footer slot="footer">
                <div class="space-x-4 text-right">
                    <a 
                        class="button" 
                        @click="showPortalNew = false">
                        
                        Avbryt
                    </a>
                    
                    <button 
                        type="submit"
                        @click.prevent="onPortalCreate()" 
                        :disabled="loading == 'creating' || !portalNew.accessCodeCheckedOk"
                        class="button submit"
                        :class="{'loading' : loading == 'creating'}"
                        :title="!portalNew.accessCodeCheckedOk ? 'Trenger en gyldig og unik tilgangskode' : ''">
                        
                        Opprett
                    </button>
                </div>
            </footer>
        </Modal>
        
        
        <div 
            v-if="portalEdit"
            class="absolute inset-0 flex">
            
            <div 
                @click="portalEdit = null"
                class="flex-grow bg-black opacity-25 ">
                
            </div>
            
            <transition name="fade">
                <PortalEdit 
                    :portal="portalEdit" 
                    @close="portalEdit = null"
                    class="
                        absolute bg-white right-0 overflow-hidden w-full
                        md:rounded-l-lg 
                        md:w-150
                        md:shadow-2xl
                    "
                />
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            'PortalCard': () => import('@/components/portals/PortalCard.vue'),
            'PortalEdit': () => import('@/components/portals/PortalEdit.vue'),
        },
        
        computed:{
            departments(){
                return this.$store.getters.getDepartments;
            },
            
            portals(){
                return this.$store.getters.getPortals;
            },
        },
        
        data(){
            return {
                portalEdit: null,
                showPortalNew: false,
                loading: null,
                portalNew: {
                    name: null,
                    accessCode: null,
                    accessCodeCheckedOk: false,
                },
            }
        },
        
        methods: {
            async onPortalAccessCodeExistCheck(){
                try {
                    this.portalNew.accessCodeCheckedOk = false;
                    
                    if ( !this.portalNew.accessCode || this.portalNew.accessCode.length <= 4 ) {
                        return;
                    }
                    
                    this.loading = 'checkingAccessCode';
                    const accessCodeOkResponse = await this.$store.dispatch('checkAccessCode', this.portalNew.accessCode);
                    this.portalNew.accessCodeCheckedOk = accessCodeOkResponse.accessCodeOk;
                } 
                catch (err) {
                    console.log(err);
                    this.$notify( {type: 'error', text: err.message} );
                }
                finally{
                    this.loading = null;
                }
            },
            
            
            async onPortalCreate(){
                try {
                    if ( !this.portalNew.name ) {
                        throw Error('En fellesbruker må ha et navn');
                    }
                    
                    if ( !this.portalNew.accessCode || this.portalNew.accessCode.length <= 4 ) {
                        throw Error('Tilgangskoden må inneholde minst fem bokstaver eller tall');
                    }
                    
                    if ( !this.portalNew.accessCodeCheckedOk ) {
                        throw Error('Tilgangskoden må være gyldig og unik');
                    }
                    
                    this.loading = 'creating';
                    
                    const portal = await this.$store.dispatch('createPortal', this.portalNew);
                    console.log('portal response', portal);
                    
                    this.$notify({ type: 'success', text: 'Fellesbrukeren ble opprettet' });
                    
                    this.showPortalNew = false;
                    
                    this.portalNew = {
                        name: null,
                        accessCode: null,
                    };
                    
                    this.portalEdit = portal;
                    
                } 
                catch (err) {
                    console.log(err);
                    this.$notify( {type: 'error', text: err.message} );
                }
                finally{
                    this.loading = null;
                }
            },
            
            hashFnv32a(str, asString = true, seed) {
                /**
                * Calculate a 32 bit FNV-1a hash
                * Found here: https://gist.github.com/vaiorabbit/5657561
                * Ref.: http://isthe.com/chongo/tech/comp/fnv/
                *
                * @param {string} str the input value
                * @param {boolean} [asString=false] set to true to return the hash value as 
                *     8-digit hex string instead of an integer
                * @param {integer} [seed] optionally pass the hash of the previous chunk
                * @returns {integer | string}
                */
                
                let i, l,
                    hval = (seed === undefined) ? 0x811c9dc5 : seed;
                    
                for (i = 0, l = str.length; i < l; i++) {
                    hval ^= str.charCodeAt(i);
                    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
                }
                if( asString ){
                    // Convert to 8 digit hex string
                    return ("0000000" + (hval >>> 0).toString(16)).substr(-8);
                }
                return hval >>> 0;
            },
            
            generateAccessCode(){
                const timestamp = new Date().getTime();
                this.portalNew.accessCode = this.hashFnv32a(timestamp+''); // MUST be a string.
                this.onPortalAccessCodeExistCheck();
            },
        },
        
        async mounted(){
            this.loading = 'fetching';
            await this.$store.dispatch('listPortals');
            this.loading = null;
        },
    }
</script>